<template>
  <div class="table-wrapper">
    <div class="table-content">
      <el-table
        ref="multipleTable"
        :row-key="rowKey"
        :default-sort="sorting"
        :data="tableRows"
        :height="height"
        :cell-class-name="tableCellClassName"
        :row-class-name="tableRowClassName"
        border
        @selection-change="handleSelectionChange"
        @sort-change="sortChange"
      >
        <slot name="beforeColumns" />
        <el-table-column
          v-if="enableSelection"
          type="selection"
        />
        <el-table-column
          v-for="(column, index) in columns"
          :key="index"
          :prop="index"
          :label="$t(`filters.${column.label}`)"
          :sortable="column.sortable"
          :align="column.align"
          :min-width="column.minWidth ? column.minWidth : ''"
        >
          <template
            slot-scope="scope"
          >
            <span v-if="column.type==='text'">{{ propertyValue(scope.row, column.key) }}</span>
            <!-- Just for displaying paymentType while testing -->
            <!-- <span v-if="column.type==='text'">{{ scope.row.paymentType + " " +
            propertyValue(scope.row, column.key) }}</span> -->
            <span v-if="column.type==='translatedText'">
              {{ $t(`filters.${propertyValue(scope.row, column.key)}`) }}
            </span>
            <span v-if="column.type === 'boolean'">
              <!-- <el-tag
                v-if="scope.row[index]"
                type="success"
              >√</el-tag>
              <el-tag
                v-else
                type="danger"
              >x</el-tag> -->
              <i
                v-if="scope.row[index]"
                class="state-icon fas fa-check-circle"
              />
              <i
                v-else
                class="state-icon fas fa-do-not-enter"
              />
            </span>
            <span v-if="column.type==='date'">
              {{ propertyValue(scope.row, column.key) | date('DD MMMM YYYY') }}
            </span>
            <span v-if="column.type==='enum'">
              {{ $t(`enums.${column.enum}.${propertyValue(scope.row, column.key)}`) }}
            </span>
            <span v-if="column.type==='encoded'">
              {{
                $t(`enums.${column.enum}.${column.decode(propertyValue(scope.row, column.key))}`)
              }}
            </span>
            <span v-if="column.type==='function' && !column.enum">
              {{ column.get(propertyValue(scope.row, column.key), scope.row) }}
            </span>
            <span v-if="column.type==='function' && column.enum">
              {{
                $t(`enums.${column.enum}.${
                  column.get(propertyValue(scope.row, column.key), scope.row)
                }`)
              }}
            </span>
            <span
              v-if="column.type==='slot-collapse-list'"
              class="slot-collapse-list"
            >
              <el-collapse
                accordion
              >
                <el-collapse-item
                  :title="$t(column.collapseTitle)"
                  name="slot-collapse-item"
                >
                  <ul>
                    <li
                      v-for="(el, collapseIndex) in scope.row[column.collapseKey]"
                      :key="`${column.collapseKey}-${collapseIndex}`"
                    >
                      <el-button
                        v-if="(scope.row.status === SAMPLE_STATUSES.DRAFT &&
                          el[column.collapseAddon] === SAMPLE_REPORTS_STATUSES.draft &&
                          el[column.collapseValue]) ||
                          el[column.collapseAddon] !== SAMPLE_REPORTS_STATUSES.draft"
                        size="mini"
                        type="text"
                        @click.prevent="$emit('collapseItemClick', scope.row, el)"
                      >
                        <i
                          :class="`state-icon fas fa-${column.collapseItemIcon}`"
                        />
                        {{ $t(`collapse.${el[column.collapseValue]}_${el[column.collapseAddon]}`) }}
                      </el-button>
                    </li>
                  </ul>
                </el-collapse-item>
              </el-collapse>
            </span>
            <TableActions
              v-else-if="column.type === 'actions'"
              :has-permissions="hasPermissions"
              :configuration="column"
              :row="scope.row"
              @actionClick="$emit($event, scope.row)"
            />
          </template>
          <template
            v-if="column.help"
            slot="header"
          >
            {{ $t(`filters.${column.label}`) }}
            <info-tooltip :tooltip-content="$t(`tooltips.${column.help}`)" />
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-row
      :gutter="20"
      type="flex"
      align="bottom"
      class="pagination-content"
    >
      <el-col
        v-if="enableSelection && bulkDisable"
        :span="8"
      >
        <div class="massive-action-disable">
          <div>{{ $t('tableButtons.disableSelected') }}</div>
          <el-button
            class="disable-selected"
            type="primary"
            @click="$emit('bulkDisable', multipleSelection)"
          >
            {{ $t('global.apply') }}
          </el-button>
        </div>
      </el-col>
      <el-col
        v-if="enableSelection && massiveActionsOptions.length"
        :span="8"
      >
        <div class="massive-actions">
          <label class="massive-actions__label">
            {{ $t('table.massiveActions.title') }}
          </label>
          <div class="massive-actions__select">
            <el-select
              v-model="massiveActionsValue"
              :disabled="!multipleSelection.length"
              :placeholder="$t('global.select')"
              size="mini"
            >
              <el-option
                v-for="item in massiveActionsOptions"
                :key="item.value"
                :label="$t(item.label)"
                :value="item.value"
                :disabled="item.disabled"
              />
            </el-select>
            <el-button
              :disabled="massiveActionsValue == null ||
                massiveActionsValue === '' || !multipleSelection.length"
              type="primary"
              size="mini"
              @click="$emit('massiveAction', massiveActionsValue, multipleSelection)"
            >
              {{ $t('table.massiveActions.submit') }}
            </el-button>
          </div>
        </div>
      </el-col>
      <el-col
        :span="24"
      >
        <el-pagination
          class="pagination"
          :current-page="currentPage"
          :page-sizes="[5, 10, 50, 100]"
          :page-size="perPage"
          background
          layout="sizes, prev, pager, next"
          :total="count"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </el-col>
    </el-row>
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex';
import lodashGet from 'lodash.get';
import TableActions from '@/components/TableActions.vue';
import InfoTooltip from '@/components/InfoTooltip.vue';
import acl from '../services/acl';
import { SAMPLE_STATUSES, SAMPLE_REPORTS_STATUSES } from '../config/constants';


export default {
  name: 'TableWrapper',
  components: {
    TableActions,
    InfoTooltip,
  },
  props: {
    tableColumns: {
      type: Object,
      default: () => {},
    },
    tableKey: {
      type: String,
      required: true,
    },
    enableSelection: {
      type: Boolean,
      default: false,
    },
    bulkDisable: {
      type: Boolean,
      default: false,
    },
    hasPermissions: {
      type: Boolean,
      default: true,
    },
    columnCondition: {
      type: String,
      default: '',
    },
    massiveActionsOptions: {
      type: Array,
      default: () => [],
    },
    height: {
      type: String,
      default: undefined,
    },
    rowKey: {
      type: String,
      default: undefined,
    },
    treeProps: {
      type: Object,
      default: undefined,
    },
    rowClassName: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      multipleSelection: [],
      massiveActionsValue: '',
      SAMPLE_STATUSES,
      SAMPLE_REPORTS_STATUSES,
    };
  },
  computed: {
    ...mapGetters([
      'tablesStatus',
    ]),
    sorting() {
      return this.tablesStatus[this.tableKey].sorting;
    },
    currentPage() {
      return this.tablesStatus[this.tableKey].page;
    },
    perPage() {
      return this.tablesStatus[this.tableKey].perPage;
    },
    count() {
      return this.tablesStatus[this.tableKey].count;
    },
    tableRows() {
      return this.tablesStatus[this.tableKey].rows;
    },
    columns() {
      return Object.keys(this.tableColumns).reduce((columns, key) => {
        if (acl.userCanUse(this.tableColumns[key].permissions, true)
          && this.checkColumnCondition(this.tableColumns[key])) {
          return { ...columns, [key]: this.tableColumns[key] };
        }
        return columns;
      }, {});
    },
  },
  created() {
    // check if use permissions
    // if (this.hasPermissions) {
    // filter columns by permissions
    // this.columns = Object.keys(this.tableColumns).reduce((columns, key) => {
    //   if (acl.userCanUse(this.tableColumns[key].permissions)
    //     && this.checkColumnCondition(this.tableColumns[key])) {
    //     return { ...columns, [key]: this.tableColumns[key] };
    //   }
    //   return columns;
    // }, {});
    // }
    // else {
    //   this.columns = { ...this.tableColumns };
    // }
  },
  mounted() {
    // TODO logics to retrieve filters from url
    this.$emit('reload');
  },
  methods: {
    ...mapActions([
      'updateTableSorting',
      'updateTablePerPage',
      'updateTableCurrentPage',
      'updateColumnSort',
    ]),
    checkColumnCondition(column) {
      return !column.columnCondition
        || (column.columnCondition && column.columnCondition === this.columnCondition);
    },
    tableCellClassName({ row, column }) {
      if (this.columns[column.property] && this.columns[column.property].customClass) {
        const modifier = this.columns[column.property].getClassModifier
          && `table-cell-${this.columns[column.property].key}--${this.columns[column.property].getClassModifier(row[column.property])}`;
        return `table-cell-${this.columns[column.property].key} ${modifier || ''}`;
      }
      return '';
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.$emit('selectionChange', val);
    },
    sortChange(sorting) {
      const { column, ...sortData } = sorting;
      this.$router.replace({
        query: {
          ...this.$route.query,
          tableKey: this.tableKey,
          sorting: JSON.stringify({ ...sortData, prop: this.columns[sortData.prop].key }),
        },
      }).catch(() => {});
    },
    handleSizeChange(perPage) {
      // this.updateTablePerPage({ tableKey: this.tableKey, perPage });
      // this.updateTableCurrentPage({ tableKey: this.tableKey, page: 1 });
      // this.$emit('reload');
      this.$router.replace({
        query: {
          ...this.$route.query,
          tableKey: this.tableKey,
          perPage,
          page: 1,
        },
      }).catch(() => {});
    },
    handleCurrentChange(page) {
      // this.updateTableCurrentPage({ tableKey: this.tableKey, page });
      // this.$emit('reload');
      this.$router.replace({
        query: {
          ...this.$route.query,
          tableKey: this.tableKey,
          page,
        },
      }).catch(() => {});
    },
    propertyValue(object, path) {
      return lodashGet(object, path, '');
    },
    tableRowClassName({ row }) {
      return this.rowClassName(row);
    },
  },
};
</script>

<style lang="scss">

  .table-wrapper {
    .el-table {
      th {
        padding: 6px 0;
      }
      td {
        padding: 5px 0;
      }
    }
    .el-pagination.pagination {
      display: flex;
      justify-content: flex-end;
      padding-top: 1rem;
    }

    .massive-actions {
      display: flex;
      flex-direction: column;

      &__label {
        color: $--color-primary;
        font-size: $--form-label-font-size;
        line-height: 1;
        margin-top: 1.5rem;
        margin-bottom: 0.5rem;
      }

      &__select {
        display: flex;

        .el-button {
          margin-left: .5rem;
        }
      }
    }

    .state-icon {
      font-size: 1.2rem;
      &.fa-check-circle {
        color: $table-action-green;
      }
      &.fa-do-not-enter {
        color: $table-action-red-light;
      }
    }

    .massive-action-disable {
      margin-top: 1rem;
      .disable-selected {
        margin-top: 1rem;
      }
    }

    .slot-collapse-list {
      a {
        text-decoration: none;
      }
      .el-collapse,
      .el-collapse-item__header,
      .el-collapse-item__content
      {
        border: none;
        background: transparent;
        &:hover {
          background: initial;
        }
      }
      .el-collapse-item__header {
        max-height: 2rem;
      }
      .el-collapse-item__content {
        padding-left: 0.25rem;
        // padding-top: 1rem;
        ul {
          padding: 0;
          margin: 0;
          li {
            display: inline-block;
            margin: 0 0.1rem;
          }
        }
      }
    }

  }

</style>
